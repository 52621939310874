import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - La fin des fins?" />
    <h3 className='underline-title'>La fin des fins?</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je percevais un début d'une fin<br />
        fin sombre<br />
      </p>
      <p>
        Un cri s'échappait de ma voix alarmante:<br />
        - lorsqu'une épouse se profanait dans son lit conjugal <br />
        <span className="par-starting"></span>une fille se violentait en présence de son père <br />
        <span className="par-starting"></span>un fils s'égorgeait devant sa mère en larmes <br />
      </p>
      <p>
        En vérité <br />
        Je croyais que ma voix portait loin au moment où<br />
        <span className="par-starting"></span>- la tête adultérée de factices <br />
        <span className="par-starting"></span>- les yeux dilatés d'insomnies <br />
        <span className="par-starting"></span>- la bouche élargie de braillements <br />
        <span className="par-starting"></span>- la poitrine asphyxiée de pollutions <br />
        <span className="par-starting"></span>- les mains enflammées d'applaudissements <br />
        <span className="par-starting"></span>- le ventre creusé de polyphagies <br />
        <span className="par-starting"></span>- les pieds prostrés de marches <br />
      </p>
      <p>
        Seigneur<br />
        <span className="par-starting"></span>la sonnette d'alarme n'était pas audible!<br />
        <span className="par-starting"></span>ils disent que c'était des jérémiades!<br />
        <span className="par-starting"></span>"nyangala kata!" m'ont-ils flanqué en pleine figure!<br />
      </p>
      <p>
        Désormais<br />
        <span className="par-starting"></span>les charognards n'attendent plus un cadavre<br />
        <span className="par-starting"></span>eux-mêmes produisent leurs cadavres <br />
        <span className="par-starting"></span>les lions n'engloutissent plus leurs proies pour <br />
        <span className="par-starting"></span>ils vivent pour trucider leurs victimes<br />
      </p>
      <p>
        La fin?<br />
        peut-être<br />
        Et si c'est la fin <br />
        C'est donc la fin ultime <br />
        <span className="par-starting"></span>la fin extrême <br />
        <span className="par-starting"></span>la fin des fins<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
